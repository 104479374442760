/**
 * @Author zhoufeng
 * @CreateTime 2022/1/6 09:02
 * @Description
 */
import React from 'react'

export const NotFound = () => {
  return <div>NotFound</div>
}
